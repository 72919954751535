import { default as React } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { getMarkdownNodeBySlug, getPageItemByScope, getSetting, hasPageItemByScope } from "@definitions/utils";
import { CheckoutContainer, HeroContainer, SubscribeContainer } from "@containers";
import { HeaderLayout, FooterLayout, Layout } from "@layouts";


const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexDeepDiveEgoHackedArtQuery {
      allMarkdown: allMarkdownRemark(
        filter: {frontmatter: {scope: {eq: "index-books"}}}
      ) {
        edges {
          node {
            id
            frontmatter {
              author
              categories
              date(formatString: "LLL")
              scope
              slug
              title
            }
            html
          }
        }
      }

      footer: layout(layoutMetadata: {scope: {eq: FOOTER}}) {
        ...Footer
      }

      header: layout(layoutMetadata: {scope: {eq: HEADER}}) {
        ...Header
      }

      page: page(
        pageMetadata: {scope: {eq: "index-books_deep-dive-ego-hacked-art"}}
      ) {
        ...Page
      }

      router: layout(layoutMetadata: {scope: {eq: ROUTER}}) {
        ...Router
      }

      site: site {
        ...Site
      }
    }
  `);

  return (
    <Layout
      title={getSetting(data.page?.pageSettings, "title")}
      className="white-version"
    >
      {(data.header && data.router) && (
        <HeaderLayout
          data={{
            header: data.header,
            router: data.router,
            socials: data.site?.siteMetadata?.socials
          }}
          className="rn-d-none"
        />
      )}
        <main className="main-page-wrapper">
          {hasPageItemByScope(data.page?.items, "hero") && (
            <HeroContainer
              id="hero"
              data={getPageItemByScope(data.page?.items, "hero")}
            />
          )}

          {hasPageItemByScope(data.page?.items, "deep-dive-ego-hacked-art") && (
            <React.Fragment>
              <CheckoutContainer
                id="book"
                data={{
                  item: getPageItemByScope(data.page?.items, "deep-dive-ego-hacked-art"),
                  node: getMarkdownNodeBySlug(data.allMarkdown?.edges, "deep-dive-ego-hacked-art"),
                  product: {
                    code: "354044345a",
                    price: "price_1Oi5WNA6n8siiQBr8SYzicsB", // live 44.00 EUR
                    // price: "price_1NywyWA6n8siiQBr8w7fiAlH", // live 29.00 EUR
                    // price: "price_1NzKiYA6n8siiQBr54FqT0KU", // test
                    quantity: 1,
                    text: "Buy for 29€ incl. VAT"
                  }
                }}
              />
            </React.Fragment>
          )}

          <div id="info" className="rn-project-area rn-section-gap">
            <div className="container">
              <div className="row justify-content-md-center">
                <StaticImage src="../../../assets/images/books/deep-dive-ego-hacked-art/skyscraper.jpg" alt="Poster" />
              </div>
            </div>
          </div>

          <div className="container">
            <SubscribeContainer
              id="subscribe"
            />
          </div>
        </main>
      {(data.footer && data.footer) && (
        <FooterLayout
          data={{
            header: data.header,
            footer: data.footer
          }}
          className="section-separator"
        />
      )}
    </Layout>
  );
}


export default IndexPage;
